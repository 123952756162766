import React from 'react';
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';
import IconImage1 from '../../images/servicehomecards_1.svg';
import IconImage2 from '../../images/servicehomecard_2.svg';
import IconImage3 from '../../images/servicehomecard_3.svg';
import IconImage4 from '../../images/servicehomecard_4.svg';
import IconImage5 from '../../images/servicehomecard_5.svg';
import IconImage6 from '../../images/servicehomecard_6.svg';
import IconImage7 from '../../images/servicehomecard_7.svg';
import IconImage8 from '../../images/servicehomecard_8.svg';
import IconImage9 from '../../images/servicehomecard_9.svg';
import { useNavigate } from 'react-router-dom';

const WebDevelopmentCard = () => {
    const navigate = useNavigate();
    return (
        <Grid container spacing={3} justifyContent="center" mt={7} px={3}>
            {/* 1st Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        overflowX:'hidden',
                        overflowY:'hidden',
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        01
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage1} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Web Development
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Creating dynamic and responsive web solutions tailored to your business needs.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                                 onClick={() => navigate('/servicedetail')}
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 2 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        02
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage2} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                UI/UX Design
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Crafting intuitive and user-centric interfaces that enhance user experience.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 3 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        03
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage3} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Digital Marketing
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Driving online visibility and engagement through targeted digital strategies.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 4 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        04
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage4} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Business Analysis
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Analyzing business processes to optimize efficiency and performance.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 5 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        05
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage5} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                               Software Services
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Developing scalable and customized software solutions for diverse requirements.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 6 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        06
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage6} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                               Machine Learning
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                               Harnessing data-driven insights to automate processes and improve decision-making.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 7 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        07
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage7} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                IT Management
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Ensuring seamless operation and optimization of your IT infrastructure.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 8 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        08
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage8} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Cloud Computing
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                                Leveraging cloud technology to enhance scalability, security, and efficiency.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            {/* 9 Card */}
            <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    sx={{
                        maxWidth: 345,
                        borderRadius: 2,
                        p: 3,
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        position: 'relative',
                        background: 'white',
                        overflow: 'hidden',
                        transition: 'background 0.5s ease, color 0.5s ease',
                        '&:hover': {
                            animation: 'backgroundAnimation 0.5s forwards',
                            color: 'white',
                            '& .icon': {
                                transform: 'rotateY(360deg)',
                                backgroundColor: 'white',
                            },
                            '& .cardContent': {
                                color: 'white',
                            },
                            '& .title, & .description': {
                                color: 'white',
                            },
                            '& button': {
                                color: '#684df4',
                                backgroundColor: 'white',
                            },
                            '& button:hover': {
                                backgroundColor: '#E0E7FF',
                                color: '#3f51b5',
                                '& .arrowIcon': {
                                    animation: 'popup 0.5s, moveRight 0.5s',
                                },
                            },
                        },
                        '@keyframes backgroundAnimation': {
                            '100%': { background: '#684df4' },
                        },
                        '@keyframes popup': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.2)' },
                            '100%': { transform: 'scale(1)' },
                        },
                        '@keyframes moveRight': {
                            '0%': { transform: 'translateX(0)' },
                            '100%': { transform: 'translateX(10px)' },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            fontSize: '5rem',
                            color: '#E0E7FF',
                            fontWeight: 'bold',
                        }}
                    >
                        09
                    </Box>
                    <Grid container direction="row" alignItems="center">
                        <Box
                            className="icon"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#E0E7FF',
                                borderRadius: '50%',
                                p: 2,
                                mb: 2,
                                mr: 2,
                                transition: 'transform 0.5s, background-color 0.5s',
                            }}
                        >
                            <img src={IconImage9} alt="Web Development Icon" style={{ width: 40, height: 40 }} />
                        </Box>
                        <CardContent className="cardContent">
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="title"
                                sx={{
                                    fontFamily: 'sans-serif',
                                    fontWeight: 600,
                                    transition: 'color 0.5s ease',
                                }}
                            >
                               Backup & Recovery
                            </Typography>
                            <Typography
                                variant="body2"
                                className="description"
                                sx={{
                                    color: 'textSecondary',
                                    transition: 'color 0.5s ease',
                                }}
                            >
                               Implementing robust data backup and recovery solutions to safeguard business continuity.
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#E0E7FF',
                                    color: '#3f51b5',
                                    borderRadius: 1,
                                    mt: 2,
                                    textTransform: 'none',
                                    transition: 'color 0.5s ease, background-color 0.5s ease',
                                    '&:hover': {
                                        backgroundColor: '#3f51b5',
                                        color: '#E0E7FF',
                                    },
                                }}
                                endIcon={
                                    <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                                        →
                                    </Box>
                                }
                            >
                                READ MORE
                            </Button>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            
        </Grid>
    );
};

export default WebDevelopmentCard;
