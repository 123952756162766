import React from 'react';
import { Grid, Typography } from '@mui/material';
import MarketingService from './Marketservice';
import Increase from './Increase';
import Services from './Services';
import Yearexperience from './Yearexperience';
import Latestprojects from '../componant/Latestprojects';
import Finished from './Finished';
import Workprocess from './Workprocess';
// import Team from './Team';
import Happyclints from './Happyclints';
import Consultation from './Consultation';
import Newarticles from './Newsarticles';
import Footer from './Footer';

const Homepage = () => {
  return (
    <Grid sx={{ overflowX: 'hidden', overflowY: 'hidden' }}>
      <Typography>
        <MarketingService />
        <Increase />
        <Services />
      </Typography>
      <Yearexperience />
      <Latestprojects />
      <Finished />
      <Workprocess />
      {/* <Team /> */}
      <Happyclints />
      <Consultation />
      <Newarticles />
      <Footer />
    </Grid>
  );
};

export default Homepage;
