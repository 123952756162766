import React from 'react';
import { Box, Typography, Avatar, Card, CardContent, Grid, Container } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from '../../images/avatarclints.png'; 
import image2 from '../../images/avatarclints.png'; 
import image3 from '../../images/avatarclints.png'
import image4 from '../../images/avatarclints.png'

const testimonials = [
    {
        name: "Mr Singhania",
        title: "CEO at InnovateTech ",
        image: image1,
        feedback: "SN Info Systems exceeded our expectations with their innovative approach. They provided us with robust solutions that have significantly boosted our efficiency..",
        rating: 5,
    },
    {
        name: "Usha NY ",
        title: "CEO at  Global Enterprises Ltd.",
        image: image2,
        feedback: "Choosing SN Info Systems was one of our best decisions. Their dedicated team and proactive support have helped us navigate complex IT challenges seamlessly..",
        rating: 5,
    },
    {
        name: "Shwetha K",
        title: "CTO at Digital Solutions Inc..",
        image: image3,
        feedback: "SN Info Systems stands out for its commitment to quality and customer satisfaction. They are responsive, reliable, and always deliver on time",
        rating: 5,
    },
    {
        name: "Nithin Gowda",
        title: "CTO at  TechVista Ltd.",
        image: image4,
        feedback: "Working with SN Info Systems has been a game-changer for us. Their expertise and seamless integration of solutions have propelled our business to new heights.",
        rating: 5,
    },
];

const Testimonials = () => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2, 
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Container sx={{ py: 6,overflowX:'hidden',
            overflowY:'hidden', }}>
            <Box textAlign="center" mb={6}>
                <Typography variant="body1" sx={{ color: '#684df4', mt: 1, fontWeight: 600 }}>
                    CUSTOMER FEEDBACK
                </Typography>
                <Typography variant="h2" sx={{ fontWeight: 'bold', mt: 2, fontWeight: 600, fontSize: 49 }}>
                    What Happy Clients Say
                </Typography>
                <Typography variant="h3" sx={{ color: '#684df4', fontWeight: 'bold', mt: 1, fontSize: 30 }}>
                    About Us?
                </Typography>
            </Box>
            <Slider {...sliderSettings}>
                {testimonials.map((testimonial, index) => (
                    <Box key={index} px={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card sx={{ p: 3, boxShadow: 3, mx: 3,my:1 }}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <Avatar
                                                        src={testimonial.image}
                                                        alt={testimonial.name}
                                                        sx={{ width: '150px', height: '150px', margin: 'auto', borderRadius: 2 }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <Typography variant="body2" color="textSecondary">
                                                    {testimonial.feedback}
                                                </Typography>
                                                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                        {testimonial.name}
                                                    </Typography>
                                                    <Box display="flex">
                                                        {[...Array(testimonial.rating)].map((_, i) => (
                                                            <StarIcon key={i} sx={{ color: '#684df4' }} />
                                                        ))}
                                                    </Box>
                                                </Box>
                                                <Typography variant="body2" color="textSecondary">
                                                    {testimonial.title}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Slider>
            
        </Container>
        
    );
};

export default Testimonials;
