import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Button, Card, CardContent } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import image from '../../images/banner (3).png';
import certifiedIcon from '../../images/aboutusitsolutionlogo1.svg';
import expertIcon from '../../images/aboutitsolutionlogo2.svg';

const About = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Container sx={{ py: 8 }}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
          <img
            src={image}
            alt="About Us"
            style={{ width: '100%', borderRadius: '1px' }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: '#684df4',
              color: 'white',
              width: {md:'160px',xs:'100px'},
              height: {md:'160px',xs:'100px'},
              position: 'absolute',
              top: {md:'350px',xs:'190px'},
              left: 'calc(70% - 60px)',
              transition: 'transform 0.3s ease',
              transform: isHovered ? 'translateY(-20px)' : 'translateY(0)',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Typography variant="h2" component="span" sx={{ fontWeight: 800,fontSize:{xs:'30px'} }}>
              5+
            </Typography>
            <Typography variant="body2" component="span" sx={{ fontWeight: 800,fontSize:{xs:'10px'} }}>
              Years Experience
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Typography variant="h5" sx={{ color: '#684df4', fontWeight: 800,textAlign:{xs:'center'},fontSize:{xs:'20px'} }} gutterBottom>
              ABOUT US
            </Typography>
            <Typography variant="h2" gutterBottom sx={{ fontFamily: 'sans-serif', fontWeight: 700, fontSize: { md: 36,xs:25 },textAlign:{xs:'center'} }}>
            Empowering Business Success Through 
            <span style={{ color: '#684df4',marginLeft:6 }}>Innovative Solutions</span>
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary',textAlign:{xs:'center'} }} paragraph>
            At SN Info Systems, we empower businesses with innovative IT solutions. Through collaboration and strategic alignment, we craft competitive advantages and foster synergy to drive your success.
            </Typography>
          </Box>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <Card elevation={0} sx={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', height: '100%' }}>
                <img src={certifiedIcon} alt="Certified Icon" style={{ width: '50px', height: '60px', marginRight: '12px' }} />
                <CardContent sx={{ padding: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Certified Company</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Highest standards of quality and reliability
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card elevation={0} sx={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', height: '100%' }}>
                <img src={expertIcon} alt="Expert Team Icon" style={{ width: '50px', height: '60px', marginRight: '12px' }} />
                <CardContent sx={{ padding: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Expert Team</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Extensive  knowledge and technical expertise
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Button variant="contained" sx={{ backgroundColor: '#684df4', mr: 2,top:{md:10} }}>
            DISCOVER MORE
          </Button>
          <Button variant="outlined" sx={{ color: '#684df4', borderColor: '#684df4',top:{xs:10} }} startIcon={<PhoneIcon />}>
            Call Us On: +91 8867325152
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
