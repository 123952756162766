import React from 'react';
import { Box, Typography, Grid, Link, TextField, Button, InputAdornment } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn, Email } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Phone, Person } from '@mui/icons-material';

const Footer = () => {
    const commonStyles = {
        fontFamily: 'Arial, sans-serif',
    };

    return (
        <Box sx={{ backgroundColor: '#233098', color: 'white', padding: 0 }}>
            {/* Subscription Section */}
            <Box sx={{
                backgroundColor: '#f4f4f5',
                color: 'black',
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                marginBottom: 4
            }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'sans-serif',marginTop:3,marginBottom:2 }}>Subscribe to Our Newsletter</Typography>
                    <Typography sx={{ fontSize: '18px', color: '#233098',marginBottom:2 }}>Subscribe to Our Newsletter for Exclusive Updates and Opportunities</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' }, marginTop: 2,marginBottom:2 }}>
                    <TextField
                        variant="outlined"
                        placeholder="Enter your email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email sx={{ color: '#233098', fontSize: '20px' }} />
                                </InputAdornment>
                            ),
                            sx: {
                                color: '#233098',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#233098',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#233098',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#233098',
                                    },
                                },
                            },
                        }}
                        sx={{
                            marginBottom: { xs: '5px', md: 0 },
                            marginRight: { md: '10px' },
                            input: { color: '#233098', fontSize: '16px' },
                            width: { xs: '100%', md: '250px' } 
                        }}
                    />
                    <TextField
                        variant="outlined"
                        placeholder="Enter your phone number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Phone sx={{ color: '#233098', fontSize: '20px' }} />
                                </InputAdornment>
                            ),
                            sx: {
                                color: '#233098',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#233098',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#233098',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#233098',
                                    },
                                },
                            },
                        }}
                        sx={{
                            marginBottom: { xs: '5px', md: 0 },
                            marginRight: { md: '10px' },
                            input: { color: '#233098', fontSize: '16px' },
                            width: { xs: '100%', md: '250px' } 
                        }}
                    />
                    <TextField
                        variant="outlined"
                        placeholder="Enter your name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person sx={{ color: '#233098', fontSize: '20px' }} />
                                </InputAdornment>
                            ),
                            sx: {
                                color: '#233098',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#233098',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#233098',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#233098',
                                    },
                                },
                            },
                        }}
                        sx={{
                            marginBottom: { xs: '5px', md: 0 },
                            marginRight: { md: '10px' },
                            input: { color: '#233098', fontSize: '16px' },
                            width: { xs: '100%', md: '250px' } 
                        }}
                    />
                    <Button variant="contained" sx={{
                        backgroundColor: '#233098',
                        color: 'white',
                        height: '46px',
                        fontSize: '14px',
                        width: { xs: '100%', md: '250px' }, 
                        '&:hover': { backgroundColor: '#233098' }
                    }}>
                        SUBSCRIBE NOW
                    </Button>
                </Box>
            </Box>

            {/* Main Content Section */}
            <Box sx={{ padding: '20px' }}>
                <Grid container spacing={2}> {/* Reduced grid spacing */}
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" sx={{ ...commonStyles, marginBottom: '8px', fontWeight: 'bold' }}>ABOUT COMPANY</Typography> {/* Reduced margin bottom */}
                        <hr style={{ border: '1px solid #684df4', width: '70px', margin: '0', marginBottom: '8px' }} /> {/* Reduced width and margin bottom */}
                        <Typography sx={commonStyles}>
                            Professionally redefine transparent ROI through low-risk high-yield imperatives.
                            Progressively create empowered, cost-effective users via team driven.
                        </Typography>
                        <Box sx={{ display: 'flex', marginTop: '8px' }}> {/* Reduced margin top */}
                            <Link href="#" color="inherit" sx={{ ...commonStyles, marginRight: '8px', '&:hover': { color: '#684df4' } }}><Facebook /></Link> {/* Reduced margin right */}
                            <Link href="#" color="inherit" sx={{ ...commonStyles, marginRight: '8px', '&:hover': { color: '#684df4' } }}><Twitter /></Link>
                            <Link href="#" color="inherit" sx={{ ...commonStyles, marginRight: '8px', '&:hover': { color: '#684df4' } }}><Instagram /></Link>
                            <Link href="#" color="inherit" sx={{ ...commonStyles, '&:hover': { color: '#684df4' } }}><LinkedIn /></Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" sx={{ ...commonStyles, marginBottom: '8px', fontWeight: 'bold' }}>QUICK LINKS</Typography> {/* Reduced margin bottom */}
                        <hr style={{ border: '1px solid #684df4', width: '70px', margin: '0', marginBottom: '8px' }} /> {/* Reduced width and margin bottom */}
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>About Us</Link> {/* Reduced margin bottom */}
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>Team</Link>
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>FAQ</Link>
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>Projects</Link>
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>Contact Us</Link>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" sx={{ ...commonStyles, marginBottom: '8px', fontWeight: 'bold' }}>IT SERVICES</Typography> {/* Reduced margin bottom */}
                        <hr style={{ border: '1px solid #684df4', width: '70px', margin: '0', marginBottom: '8px' }} /> {/* Reduced width and margin bottom */}
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>Web Development</Link> {/* Reduced margin bottom */}
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>Business Development</Link>
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>Product Management</Link>
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>UI/UX Design</Link>
                        <Link href="#" color="inherit" display="block" sx={{ ...commonStyles, marginBottom: '4px', '&:hover': { color: '#684df4' }, textDecorationLine: 'none' }}>Cloud Services</Link>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" sx={{ ...commonStyles, marginBottom: '8px', fontWeight: 'bold', color: '#fff' }}>Contact Us</Typography> {/* Reduced margin bottom */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: '#fff' }}>
                            <EmailIcon sx={{ marginRight: '8px' }} />
                            <Box>
                                <Typography variant="body1" sx={{ ...commonStyles, fontWeight: 'bold' }}>Email:</Typography>
                                <Typography variant="body2" sx={commonStyles}>info@sninfosystems.com</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: '#fff' }}> {/* Reduced margin bottom */}
                            <PhoneIcon sx={{ marginRight: '8px' }} />
                            <Box>
                                <Typography variant="body1" sx={{ ...commonStyles, fontWeight: 'bold' }}>Phone:</Typography>
                                <Typography variant="body2" sx={commonStyles}>+91 9740857368</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: '#fff' }}> {/* Reduced margin bottom */}
                            <WhatsAppIcon sx={{ marginRight: '8px' }} />
                            <Box>
                                <Typography variant="body1" sx={{ ...commonStyles, fontWeight: 'bold' }}>WhatsApp:</Typography>
                                <Typography variant="body2" sx={commonStyles}>+91 9740857368</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px', color: '#fff' }}> {/* Reduced margin bottom */}
                            <LocationOnIcon sx={{ marginRight: '8px' }} />
                            <Box>
                                <Typography variant="body1" sx={{ ...commonStyles, fontWeight: 'bold' }}>Address:</Typography>
                                <Typography variant="body2" sx={commonStyles}>Basavanagudi,Bangaluru,Karnataka</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Footer Bottom Section */}
            <Box sx={{ textAlign: 'center', padding: '10px' }}>
                <Typography sx={{ color: '#fff', fontSize: '14px' }}> {/* Reduced font size */}
                    &copy; 2023 SN Info Systems. All Rights Reserved.
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;
