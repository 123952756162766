import React from 'react';
import { Box, Button, Typography, Grid, Container } from '@mui/material';
import backgroundImage from '../../images/servicehomebackgroundimage.jpg';

const Banner = () => {
    return (
        <Box
            sx={{
                overflowX:'hidden',
                overflowY:'hidden',
                mt:10,
                height: '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.5)', 
                    zIndex: 1,
                },
            }}
        >
            <Container sx={{ zIndex: 2, textAlign: 'center' }}>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <Typography variant="body1" sx={{ color: '#684df4', mt: 1,fontWeight:600 }}>
                            CONTACT US
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" sx={{ color: 'white',fontFamily:'sans-serif', fontWeight:700,fontSize:{md:40,xs:20}, mt: 2 }}>
                        Need Any Kind Of Technology Solution 

                        </Typography>
                        <Typography variant="h3" sx={{ color: '#684df4', fontWeight: 'bold', mt: 1,fontSize:{md:40,xs:20} }}>
                        For  Your Business?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                mt: 4,
                                backgroundColor: '#684df4',
                                color: 'white',
                                borderRadius: 2,
                                px: 4,
                                py: 1.5,
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: '#684df4',
                                },
                            }}
                        >
                            GET IN TOUCH →
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Banner;
