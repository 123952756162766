import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Grid, Paper, Container, ThemeProvider } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import processcard1 from '../images/process_card_1.svg';
import processcard2 from '../images/process_card_2.svg';
import processcard3 from '../images/process_card_3.svg';
import processcard4 from '../images/process_card_4.svg';

const theme = createTheme();

const steps = [
    {
        title: 'Select a Project',
        description: 'We assist in identifying your needs and setting clear objectives.',
        icon: processcard1,
    },
    {
        title: 'Project Analysis',
        description: 'We conduct a thorough analysis and provide strategic planning.',
        icon: processcard2,
    },
    {
        title: 'Plan & Execute ',
        description: 'We develop detailed plans and execute them efficiently.',
        icon: processcard3,
    },
    {
        title: 'Deliver Results',
        description: ' We ensure the delivery of high-quality and timely results.',
        icon: processcard4,
    },
];

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'box-shadow 0.3s',
    boxShadow: '0 0 0 rgba(104, 77, 244, 0.7)',
    '&:hover': {
        boxShadow: '0 0 20px 5px rgba(104, 77, 244, 0.7)', 
    },
    '&:hover img': {
        transform: 'rotateY(360deg)',
    },
}));

const Workflow = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1, mt: 10, position: 'relative', padding: '0 20px',marginBottom:8 }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                        height: 'var(--content-height)',
                        background: '#684df4',
                        zIndex: -1,
                        borderRadius: '20px',
                    }}
                />

                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h4" sx={{ fontFamily: 'sans-serif', fontSize: 20, color: '#684df4', fontWeight: 600, marginBottom: 2,marginTop:{xs:-3} }}>
                        Work Process
                    </Typography>
                    <Typography variant="h4" sx={{ fontFamily: 'sans-serif', fontWeight: 800, marginBottom: 3 }}>
                    How We Help <span style={{ color: '#684df4' }}>You!</span>
                    </Typography>
                </Box>
                <Grid container spacing={2} sx={{ marginTop:'20px' }}>
                    {steps.map((step, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Item sx={{ height: {md:'320px',xs:'190px'}, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '10px' }}>
                                <img src={step.icon} alt={step.title} style={{ height: '120px', margin: 'auto', transition: 'transform 0.3s' }} />
                                <Box>
                                    <Typography variant="h6" sx={{fontFamily:'sans-serif',fontWeight:600,color:'black'}}>
                                        {step.title}
                                    </Typography>
                                    <Typography>{step.description}</Typography>
                                </Box>
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default Workflow;
