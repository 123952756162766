import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import image from '../images/why chooseus.png';

const CircularProgressWithLabel = (props) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const CircularCounter = ({ value, label, bodyLine }) => {
    return (
        <Box sx={{ textAlign: 'center', margin: 2 }}>
            <CircularProgressWithLabel value={value} size={100} thickness={5} sx={{ color: '#7f3ff2', fontWeight: 600 }} />
            <Typography variant="h6" sx={{ fontFamily: 'sans-serif', fontWeight: 600, color: 'black', marginTop: 2 }}>{label}</Typography>
            <Typography variant="body1">{bodyLine}</Typography>
        </Box>
    );
};

const StyledContainer = styled('div')({
    perspective: '1000px',
});

const StyledImage = styled('img')({
    width: '100%',
    height: 'auto',
    transition: 'transform 0.1s ease',
    '&:hover': {
        transform: 'rotateY(10deg) rotateX(10deg)',
    },
});

const Yearexperience = () => {
    const [progress1, setProgress1] = useState(0);
    const [progress2, setProgress2] = useState(0);

    useEffect(() => {
        const timer1 = setInterval(() => {
            setProgress1((prevProgress) => (prevProgress >= 90 ? 90 : prevProgress + 1));
        }, 30);

        const timer2 = setInterval(() => {
            setProgress2((prevProgress) => (prevProgress >= 95 ? 95 : prevProgress + 1));
        }, 30);

        return () => {
            clearInterval(timer1);
            clearInterval(timer2);
        };
    }, []);

    return (
        <Grid container display="flex" sx={{ backgroundColor: '#eaedef',padding:5}}>
            <Grid item md={6}>
                <Typography variant="h4" sx={{ 
                    marginTop: 5, 
                    marginBottom: 2, 
                    fontFamily: 'sans-serif', 
                    fontWeight: 700, 
                    fontSize: 20, 
                    color: '#7f3ff2',
                   
                    
                    }}>
                    WHY CHOOSE US
                </Typography>
                <Typography variant="h2" sx={{ fontWeight: 800, fontSize: '30px', fontFamily: 'sans-serif', }}>
                Over 5+ Years of Excellence in  
                    <Typography variant="h2" sx={{ 
                        fontWeight: 800, 
                        fontSize: '30px', 
                        fontFamily: 'sans-serif',
                        
                        }}>
                    Tailored<span style={{ color: '#7f3ff2',marginLeft:9 }}>Technology Solutions</span>
                </Typography>
                </Typography>
                
                <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px' }}>
                With over 5+ years of experience, SN Info Systems stands out as a trusted partner in technology solutions. Our team is dedicated to delivering quality and innovation, ensuring your projects achieve outstanding results.
                </Typography>
                <Grid container display="flex" justifyContent="space-between"
                sx={{
                    marginLeft:{md:-3,},
                    marginTop:{md:3,xs:3}
                }}>
                     <Grid md={6} item>
                        <CircularCounter value={progress1} label="Business Growth" bodyLine="Measures the increase in business activity and revenue." />
                    </Grid>
                    <Grid md={6} item>
                        <CircularCounter value={progress2} label="Efficiency Score" bodyLine="Quantifies operational effectiveness and optimization." />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
                <StyledContainer sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:3 }}>
                    <StyledImage src={image} alt="photo" />
                </StyledContainer>
            </Grid>
        </Grid>
    );
};

export default Yearexperience;
