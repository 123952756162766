import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './componant/Homepage';
import Abouthome from './componant/aboutus/Abouthome';
import Header from './componant/Header';
import Contacthome from './componant/contactus/Contacthome';
import Servicehome from './componant/service/Servicehome';
import Servicedetail from './componant/service/Servicedetail';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/abouthome" element={<Abouthome />} />
        <Route path="/contacthome" element={<Contacthome />} />
        <Route path="/servicehome" element={<Servicehome />} />
        <Route path="/servicedetail" element={<Servicedetail />} />
      </Routes>
    </Router>
  );
};

export default App;
