import React from 'react';
import { Grid, Paper, Typography, Box, Button, Card, CardContent, List, ListItem, ListItemText, Breadcrumbs, Link } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import service1 from '../../images/servicehomecard_2.svg';
import service2 from '../../images/servicehomecard_3.svg';
import service3 from '../../images/servicehomecard_4.svg';
import serviceimage1 from '../../images/service_detailsimage.jpg';
import serviceimage2 from '../../images/service_detailimage2.jpg';
import backgroundsmallimage from '../../images/servicedetailsmallbackgroundimage.jpg';
import Footer from '../Footer';
import backgroundImage from '../../images/aboutusbacgroundimage.jpg'
import service123 from '../../images/service_card_1.svg'
const Services = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const services = [
        {
            title: 'Custom Solutions',
            description: 'Tailored web solutions designed to meet your unique business requirements and goals.',
            Image: service1,
        },
        {
            title: 'User-Centric Design',
            description: 'Intuitive and engaging designs that enhance user experience and retention.',
            Image: service2,
        },
        {
            title: 'SEO & Security',
            description: 'Websites optimized and secured for search engines to improve visibility.',
            Image: service3,
        },
    ];

    return (
        <Grid container direction="column" sx={{ overflowX: "hidden", overflowY: "hidden" }}>
            {/* servicedetail */}

            <Grid
                container
                sx={{
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#fff',
                    minHeight: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: (theme) => theme.spacing(4),
                }}
            >
                <Grid item xs={12}>
                    <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', color: '#fff' }}>
                        Services Detail
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Breadcrumbs sx={{ color: '#fff', textAlign: 'center' }}>
                        <Link href="/" sx={{ color: 'inherit', textDecoration: 'none', '&:hover': { textDecoration: 'none' } }}>
                            Home
                        </Link>
                        <Typography sx={{ color: '#ffff' }}>Services Detail</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            {/* 1st section */}
            <Grid container justifyContent="center" alignItems="center" sx={{ padding: 5 }}>
                <Grid item xs={12} md={8} sx={{ marginTop: { md: 10, xs: 5 } }}>
                    <Box sx={{ marginLeft: { md: 5.5 } }}>
                        <img
                            src={serviceimage1}
                            alt="Web Development"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </Box>
                    <Box sx={{ ml: { md: 5.5 }, mt: { md: 5 } }}>
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                            Web Development
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            At SN Info Systems, we specialize in creating dynamic and responsive web solutions tailored to meet your business needs. Our team of experienced developers and designers work collaboratively to deliver websites that not only look stunning but also perform exceptionally well. We focus on user experience, ensuring that your website is intuitive, engaging, and easy to navigate. By leveraging the latest technologies and best practices, we build websites that are scalable, secure, and optimized for search engines, helping you reach your target audience effectively.
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{marginTop:3}}>
                        Our comprehensive web development services cover everything from initial consultation and planning to design, development, testing, and deployment. Whether you need a simple brochure website, a complex e-commerce platform, or a custom web application, we have the expertise to deliver solutions that drive business growth and success.
                        </Typography>
                    </Box>

                </Grid>

                {/* Right-side section next to the first image */}
                <Grid item xs={12} md={4}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box display="flex" justifyContent="center" alignItems="center" height="auto" sx={{ mt: { xs: 5, }, mb: 5 }}>
                            <Card sx={{ padding: '30px', textAlign: 'center', maxWidth: '300px', backgroundColor: '#f5f5f5', marginLeft: { md: 2 } }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                        Download Brochure
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#6C63FF',
                                            color: 'white',
                                            textTransform: 'none',
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            width: '100%',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        DOWNLOAD PDF
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            borderColor: '#6C63FF',
                                            color: '#6C63FF',
                                            textTransform: 'none',
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            width: '100%',
                                        }}
                                    >
                                        DOWNLOAD DOC
                                    </Button>
                                </CardContent>
                            </Card>
                        </Box>

                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '70vh',
                                width: '62vh',
                                backgroundImage: `url(${backgroundsmallimage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                mt: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',

                                }}
                            />
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{ position: 'relative', zIndex: 2, textAlign: 'center', color: 'white' }}
                            >
                                <Grid item xs={12} md={9}>
                                    <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                        <CardContent>
                                            <Typography variant="h6" sx={{ mb: 1, fontWeight: 600, color: '#ffff', fontSize: { md: 15 }, whiteSpace: 'nowrap' }}>
                                                CONTACT US NOW
                                            </Typography>
                                            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2, fontSize: { md: 25 }, color: '#ffff', whiteSpace: 'nowrap' }}>
                                                You Need Help?
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    mt: 2,
                                                    backgroundColor: '#6C63FF',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: '#5a54e7',
                                                    },
                                                }}
                                            >
                                                GET A QUOTE
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* 3rd section */}
            <Grid>
                <Grid container justifyContent="center" alignItems="center" sx={{ padding: 5 }}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{ padding: '50px 20px', textAlign: 'center' }}>
                            <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold', mb: 5, marginLeft: { xs: -7 } }}>
                                Benefits With <span style={{ color: '#684df4' }}>Our Service</span>
                            </Typography>
                            <Grid container spacing={4} justifyContent="center">
                                {services.map((service, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card sx={{
                                            padding: 3,
                                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                                            width: { xs: '60%', md: '90%' },
                                            transition: 'transform 0.3s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                boxShadow: '0 6px 30px rgba(104, 77, 244, 0.5)',
                                            },
                                        }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                                <Box
                                                    component="img"
                                                    src={service.Image}
                                                    alt={service.title}
                                                    sx={{ height: 80, width: 80, borderRadius: '50%', objectFit: 'cover' }}
                                                />
                                            </Box>
                                            <CardContent>
                                                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1, fontFamily: 'sans-serif' }}>
                                                    {service.title}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {service.description}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {/* 4th section */}
            <Grid container justifyContent="center" alignItems="center" sx={{ padding: 5 }}>
                <Grid item xs={12} md={12}>
                    <Box>
                        <Typography variant="h4" component="h1" gutterBottom align="left" sx={{ marginBottom: 4, fontWeight: 600 }}>
                            Frequently Asked <span style={{ color: '#684df4' }}>Questions</span>
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={8}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{
                                            backgroundColor: expanded === 'panel1' ? '#684df4' : 'inherit',
                                            color: expanded === 'panel1' ? 'white' : 'inherit',
                                        }}
                                    >
                                        <Typography variant="h6" component="h3" sx={{ fontWeight: 600 }}>
                                        What types of web development services do you offer?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ textAlign: 'left' }}>
                                        <Typography>
                                        At SN Info Systems, we offer a comprehensive range of web development services, including custom website design, e-commerce solutions, web applications, content management systems (CMS), and website maintenance.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                        sx={{
                                            backgroundColor: expanded === 'panel2' ? '#684df4' : 'inherit',
                                            color: expanded === 'panel2' ? 'white' : 'inherit',
                                        }}
                                    >
                                        <Typography variant="h6" component="h3" sx={{ fontWeight: 600 }}>
                                        How long does it take to develop a website?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ textAlign: 'left' }}>
                                        <Typography>
                                        The timeline for web development depends on the complexity and scope of the project. Typically, a standard website can take 4-6 weeks, while more complex projects may take several months. We provide detailed timelines after an initial consultation.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                        sx={{
                                            backgroundColor: expanded === 'panel3' ? '#684df4' : 'inherit',
                                            color: expanded === 'panel3' ? 'white' : 'inherit',
                                        }}
                                    >
                                        <Typography variant="h6" component="h3" sx={{ fontWeight: 600 }}>
                                        Will my website be mobile-friendly?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ textAlign: 'left' }}>
                                        <Typography>
                                        Yes, all websites we develop are mobile-friendly and responsive. We ensure that your website looks and functions perfectly on all devices, including desktops, tablets, and smartphones.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                        sx={{
                                            backgroundColor: expanded === 'panel4' ? '#684df4' : 'inherit',
                                            color: expanded === 'panel4' ? 'white' : 'inherit',
                                        }}
                                    >
                                        <Typography variant="h6" component="h3" sx={{ fontWeight: 600 }}>
                                        Do you provide SEO services for websites?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ textAlign: 'left' }}>
                                        <Typography>
                                        Yes, we integrate SEO best practices into our web development process to help improve your website's visibility on search engines. Additionally, we offer ongoing SEO services to further enhance your online presence.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                        sx={{
                                            backgroundColor: expanded === 'panel4' ? '#684df4' : 'inherit',
                                            color: expanded === 'panel4' ? 'white' : 'inherit',
                                        }}
                                    >
                                        <Typography variant="h6" component="h3" sx={{ fontWeight: 600 }}>
                                        What kind of support do you offer after the website is launched?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ textAlign: 'left' }}>
                                        <Typography>
                                        After launching your website, we provide ongoing support and maintenance services. This includes updates, security checks, backups, and troubleshooting to ensure your website remains up-to-date and secure.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default Services;
