import React from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const Email = () => {
    return (
        <Container sx={{ py: 8 }}>
            <Grid container spacing={4} justifyContent="center">
                {[
                    { title: 'Basavanagudi', address: '37 T1,Gandhi Bazaar, Basavanagudi, Bengaluru, Karnataka 560004', phone: '+91 9740857368', email: 'basavanagudi@sninfosystems.com' },
                    { title: 'Yelahanka', address: '9A1 9A2, Satellite Town, Yelahanka, Bengaluru, Karnataka 560064', phone: '+91 9740857368', email: 'yelahanka@sninfosystems.com' },
                    { title: 'Malleshwaram', address: '12/2,Yadhava College Rd Gandhi Nagar,Bengaluru, Karnataka 560001', phone: '+91 9740857368', email: 'malleshwaram@sninfosystems.com' },
                    { title: 'Hennur', address: '# 33/3,Hennur Cross,  Bengaluru, Karnataka - 560043', phone: '+91 9740857368', email: 'hennur@sninfosystems.com' },
                ].map((info, index) => (
                    <Grid item xs={12} md={3} key={index}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',  // Align items to the left
                                textAlign: 'left',  // Align text to the left
                                p: 3,
                                borderRadius: 2,
                                boxShadow: 3,
                                bgcolor: 'white',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    boxShadow: 6,
                                    bgcolor: '#684df4',
                                    color: 'white',
                                    transform: 'scale(1.05)',
                                    '& .MuiTypography-root, & .MuiSvgIcon-root': {
                                        color: 'inherit',
                                        transition: 'transform 0.3s ease',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        transform: 'rotate(360deg)',
                                    },
                                },
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
                                {info.title}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, width: '100%' }}>
                                <LocationOnIcon sx={{ fontSize: 20, mr: 1, transition: 'transform 0.3s ease', }} />
                                <Typography variant="body2" sx={{ fontSize: 16, wordWrap: 'break-word', flex: 1 }}>
                                    {info.address}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, width: '100%' }}>
                                <PhoneIcon sx={{ fontSize: 20, mr: 1, transition: 'transform 0.3s ease' }} />
                                <Typography variant="body2" sx={{ fontSize: 16, wordWrap: 'break-word', flex: 1 }}>
                                    {info.phone}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <EmailIcon sx={{ fontSize: 20, mr: 1, transition: 'transform 0.3s ease' }} />
                                <Typography variant="body2" sx={{ fontSize: 12.5, wordWrap: 'break-word', flex: 1 }}>
                                    {info.email}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Email;
