import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Box, MenuItem, Snackbar, Alert } from '@mui/material';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        email: false,
        phone: false,
        message: false
    });

    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const isEmailValid = validateEmail(formData.email);
        const isPhoneValid = validatePhone(formData.phone);
        const isMessageValid = formData.message.trim() !== '';

        if (!isEmailValid || !isPhoneValid || !isMessageValid) {
            setErrors({
                email: !isEmailValid,
                phone: !isPhoneValid,
                message: !isMessageValid
            });
            return;
        }

        const subject = encodeURIComponent(`Appointment Request: ${formData.subject}`);
        const body = encodeURIComponent(
            `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\n\nMessage:\n${formData.message}`
        );

        window.location.href = `mailto:gowthampatel024@gmail.com?subject=${subject}&body=${body}`;

        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
        setErrors({
            email: false,
            phone: false,
            message: false
        });
        setSuccess(true);
    };

    const handleClose = () => {
        setSuccess(false);
    };

    return (
        <Container sx={{ py: 8 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            mb: 4,
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 1 ,color:'#684df4'}}>
                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', mb: 1 }}>
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'inline-block',
                                        width: 24,
                                        height: 24,
                                        bgcolor: '#684df4',
                                        borderRadius: '50%',
                                        mr: 1,
                                    }}
                                />
                                Contact Us!
                            </Box>
                        </Typography>
                        <Typography variant="h4" fontWeight="bold" gutterBottom>
                        Got Questions? Let's Connect!
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                        Reach out to us at SN Info Systems for any inquiries or discussions. We are here to facilitate direct communication and provide tailored solutions to meet your business needs. Our focus is on enhancing your business operations.
                        </Typography>
                    </Box>
                    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Enter Your Name"
                                variant="outlined"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Enter Your Email"
                                variant="outlined"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={errors.email}
                                helperText={errors.email ? 'Please enter a valid Gmail address.' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                fullWidth
                                label="Select Service"
                                variant="outlined"
                                name="Service"
                                value={formData.subject}
                                onChange={handleChange}
                            >
                                <MenuItem value="subject1">Web Development</MenuItem>
                                <MenuItem value="subject2">UI/UX Design</MenuItem>
                                <MenuItem value="subject3">Digital marketing</MenuItem>
                                <MenuItem value="subject3">Business Analysis</MenuItem>
                                <MenuItem value="subject3">Software Services</MenuItem>
                                <MenuItem value="subject3">Machine Learning</MenuItem>
                                <MenuItem value="subject3">IT Management</MenuItem>
                                <MenuItem value="subject3">Cloud Computing</MenuItem>
                                <MenuItem value="subject3">Backup & Recovery</MenuItem>
                                
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                variant="outlined"
                                name="phone"
                                value={formData.phone}
                                 onChange={handleChange}
                                error={errors.phone}
                                helperText={errors.phone ? 'Please enter a valid 10-digit phone number.' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Write Your Message"
                                variant="outlined"
                                multiline
                                rows={4}
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                error={errors.message}
                                helperText={errors.message ? 'Message cannot be empty.' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{backgroundColor:'#684df4'}}
                            >
                                Make an Appointment
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ marginTop: '20px', paddingLeft: {md:'20px',xs:'-5px'} }}>
                        <iframe
                            src=" https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.4016124886493!2d77.56768787360942!3d12.94613351543905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15eb1e779041%3A0xeaf1f608433aec66!2sGetMyCollege!5e0!3m2!1sen!2sin!4v1720166384618!5m2!1sen!2sin"
                            width="100%"
                            height="550"
                            style={{ border: 0, borderRadius: '20px' }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%',top:20 }}>
                    Message sent successfully!
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ContactForm;
