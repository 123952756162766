import React, { useState } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Container } from '@mui/material';
import Slider from 'react-slick';
import StarIcon from '@mui/icons-material/Star';
import test1 from '../../images/avatarclints.png';
import test2 from '../../images/avatarclints.png';
import test3 from '../../images/avatarclints.png';
import test4 from '../../images/avatarclints.png';
import backgroundImage from '../../images/consultationbackgroundimage.jpg';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme({
    palette: {
        primary: {
            main: '#6a52ff',
        },
    },
    typography: {
        h3: {
            fontWeight: 700,
            fontSize: '2rem',
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.5rem',
        },
    },
});

const StyledSlider = styled(Slider)({
    '& .slick-prev, & .slick-next': {
        zIndex: 1,
        width: 30, 
        height: 30, 
        '&:before': {
            fontSize: 30, 
            color: 'black', 
        },
    },
    '& .slick-prev': {
        left: -40, 
    },
    '& .slick-next': {
        right: -40,
    },
});

const HappyClients = () => {
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const renderStars = (count) => {
        return (
            <Grid container justifyContent="center">
                {Array.from({ length: count }).map((_, index) => (
                    <StarIcon key={index} sx={{ color: '#684df4' }} />
                ))}
            </Grid>
        );
    };

    const renderAvatar = (imageSrc) => {
        return (
            <Grid
                sx={{
                    width: '120px',
                    height: '120px',
                    borderRadius: '50%',
                    backgroundColor: '#7f3ff2',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    backgroundImage: `url(${imageSrc})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
        );
    };

    const cards = [
        {
            name: 'Jackline Techie',
            title: 'CEO at Kormola',
            stars: 5,
            image: test1,
            description:
                'Objectively visualize error-free technology for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
        },
        {
            name: 'Abraham Khalil',
            title: 'CEO at Anatora',
            stars: 5,
            image: test2,
            description:
                'Objectively visualize error-free technology for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
        },
        {
            name: 'Alyssia Moreso',
            title: 'CEO at Lentoria',
            stars: 5,
            image: test3,
            description:
                'Objectively visualize error-free technology for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
        },
        {
            name: 'Kylan Plath',
            title: 'CEO at Kimori',
            stars: 5,
            image: test4,
            description:
                'Objectively visualize error-free technology for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container direction="column">
                <Grid
                    item
                    sx={{
                        position: 'relative',
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '80vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: 'white',
                        paddingTop: '1vh',
                    }}
                >
                    <Grid sx={{ marginTop: { md: -18, xs: -27 } }}>
                        <Typography sx={{ fontWeight: 700, marginBottom: 2, color: '#684df4', fontSize: 20 }}>
                            CUSTOMER FEEDBACK
                        </Typography>
                        <Typography variant="h2" sx={{ fontWeight: 700, marginBottom: 2, fontFamily: 'sans-serif', fontSize: { md: 40, xs: 30 } }}>
                            What Happy Clients Say
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                            About Us?
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        marginTop: '-40vh',
                        padding: '20px',
                    }}
                >
                    <Container>
                        <StyledSlider {...settings}>
                            {cards.map((card, index) => (
                                <Grid item key={index} sx={{ padding: '0 10px' }}>
                                    <Card
                                        sx={{
                                            marginTop:2,
                                            marginBottom:2,
                                            textAlign: 'center',
                                            boxShadow: 3,
                                            padding: 4,
                                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                boxShadow: 6,
                                            },
                                        }}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <CardMedia>
                                            <Grid container justifyContent="center">
                                                {renderAvatar(card.image)}
                                            </Grid>
                                        </CardMedia>
                                        <CardContent>
                                            {renderStars(card.stars)}
                                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: '16px' }}>
                                                {card.description}
                                            </Typography>
                                            <Typography variant="h6" component="div" sx={{ marginTop: '16px', fontWeight: 600 }}>
                                                {card.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {card.title}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </StyledSlider>
                    </Container>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default HappyClients;
