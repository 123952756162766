import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import logo from '../images/SN (1).png';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/abouthome' },
    { name: 'Services', path: '/servicehome' },
    { name: 'Contact Us', path: '/contacthome' },
  ];

  return (
    <AppBar position="static" sx={{ backgroundColor: '#233098' }}>
      <Grid container justifyContent="space-between" alignItems="center" px={2} py={1}>
        {!isMobile && (
          <Grid display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
            <LocationOnIcon />
            <Typography variant="body1" ml={1} sx={{ fontSize: 16, fontWeight: 400 }}>
              Basavanagudi, Karnataka, India
            </Typography>
            <PhoneIcon sx={{ ml: 2 }} />
            <Typography variant="body1" ml={1} sx={{ fontSize: 16, fontWeight: 400 }}>
              <a href="tel:+919740857368" style={{ textDecoration: 'none', color: 'inherit' }}>
                (+91) 9740857368
              </a>
            </Typography>
            <EmailIcon sx={{ ml: 2 }} />
            <Typography variant="body1" ml={1} sx={{ fontSize: 16, fontWeight: 400 }}>
              info@sninfosystems.com
            </Typography>
          </Grid>
        )}
        <Box display="flex" alignItems="center" justifyContent={isMobile ? 'center' : 'flex-end'} flexGrow={1}>
          <Typography variant="body1" ml={1} sx={{ fontSize: 16, fontWeight: 400 }}>
            Follow Us:
          </Typography>
          <IconButton color="inherit" sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <FacebookIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <IconButton color="inherit" sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <TwitterIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.linkedin.com/company/103931743/admin/dashboard/"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <LinkedInIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <IconButton color="inherit" sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <InstagramIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <IconButton color="inherit" sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <YouTubeIcon sx={{ color: '#ffffff' }} />
          </IconButton>
        </Box>
      </Grid>
      <Toolbar
        sx={{
          backgroundColor: '#e1eaff',
          flexDirection: { xs: 'row', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" alignItems="center">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              style={{ height: '120px', width: 'auto', marginLeft: '50px' }}
            />
          </Link>
        </Box>

        {!isMobile && (
          <Box display="flex" justifyContent="center" flexWrap="wrap">
            {menuItems.map((item, index) => (
              <Button
                key={index}
                component={Link}
                to={item.path}
                sx={{ color: '#141d38', fontSize: 16, fontWeight: 400, marginRight: { xs: 0, sm: '6px', md: '25px' } }}
              >
                {item.name}
              </Button>
            ))}

            <Button
              variant="contained"
              sx={{ backgroundColor: '#233098', fontSize: 16, fontWeight: 400 }}
            >
              MAKE APPOINTMENT
            </Button>
          </Box>
        )}
        {isMobile && (
          <Box display="flex" alignItems="center">
            <IconButton color="inherit" onClick={toggleDrawer(true)} sx={{ ml: 'auto' }}>
              <MenuIcon sx={{ color: '#684df4' }} />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              <Box
                sx={{ width: 250, backgroundColor: '#e1eaff', height: '100%' }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                  <img src={logo} alt="Logo" style={{ height: '60px', width: 'auto' }} />
                  <IconButton color="inherit" onClick={toggleDrawer(false)}>
                    <CancelIcon sx={{ color: '#684df4' }} />
                  </IconButton>
                </Box>
                <Divider />
                <List>
                  {menuItems.map((item, index) => (
                    <ListItem button component={Link} to={item.path} key={index}>
                      <ArrowRightIcon sx={{ color: '#684df4' }} />
                      <ListItemText primary={item.name} sx={{ marginLeft: 2 }} />
                      <IconButton sx={{ color: '#684df4' }}>
                        <AddIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
                <Divider />
                <Box p={2} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: '#684df4', fontSize: 16, fontWeight: 400 }}
                  >
                    MAKE APPOINTMENT
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
