import React from 'react';
import {Typography, Button, Box, Grid, Container } from '@mui/material';

import backgroundImage from '../images/13.jpg';
import sectionBackgroundImage from '../images/service_bg_2.jpg';

const BusinessSolutions = () => {
    return (
        <Box
            sx={{
                height:'400px',
                flexGrow: 1,
                backgroundColor: '#f9f9f9',
                backgroundImage: `url(${sectionBackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Container>
                <Grid container spacing={4} marginTop={10}>
                    <Grid item xs={12} md={6}>
                        <Box
                            component="img"
                            sx={{
                                width: '90%',
                                borderRadius: 2,
                                marginTop:{md:3,xs:1},
                                marginBottom:{xs:-1},
                                marginLeft: 'auto', 
                                display: 'block', 
                                marginLeft:{xs:2,md:1}
                            }}
                            alt="Consultation"
                            src={backgroundImage}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom:3
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: '#684df4',
                                    marginLeft:{xs:5,md:0},
                                    marginTop:{md:8},
                                    marginBottom: 1,
                                    fontFamily: 'sans-serif',
                                    fontSize: { md: 20, xs: 15 },
                                    fontWeight: 600,
                                    textAlign: { xs: 'center', md: 'left' }, 
                                }}
                            >
                                EXPLORE OUR
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{
                                    marginLeft:{xs:5,md:0},
                                    marginBottom: 2,
                                    fontWeight: 700,
                                    color: '#ffff',
                                    fontSize: {xs:20,md:40},
                                    textAlign: { xs: 'center', md: 'left' }, 
                                }}
                            >
                                Let's Discuss Your Business<br/> 
                                <span style={{ color: '#684df4', }}>Solutions</span>
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    padding: '10px 20px',
                                    color: '#ffff',
                                    backgroundColor: '#684df4',
                                    '&:hover': { backgroundColor: '#684df4' },
                                    marginLeft:{xs:12,md:1},
                                    marginTop:{xs:2,}
                                }}
                            >
                                CONTACT US →
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default BusinessSolutions;
