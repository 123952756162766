import { Grid } from '@mui/material'
import React from 'react'
import Servicee from '../service/Servicee';
import Servicecards from '../service/Servicecards';
import Serviceitsolution from '../service/Serviceitsolution';
import Serviceworkplace from '../service/Serviceworkplace';
import Servicehappyclints from '../service/Servicehappyclints';
import Footer from '../Footer';

const Service = () => {
  return (
    <Grid>
      <Servicee/>
      <Servicecards/>
      <Serviceitsolution/>
      <Serviceworkplace/>
      <Servicehappyclints/>
      <Footer/>
    </Grid>
  )
}

export default Service