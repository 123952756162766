import React from 'react';
import { Button, Typography, Box, Grid, useMediaQuery } from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from '@mui/system';
import heroimg from '../images/Untitled design (6).png';

const RippleContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  zIndex: -1,
});

const Ripple = styled('span')(({ delay }) => ({
  position: 'absolute',
  borderRadius: '50%',
  width: '100px',
  height: '100px',
  backgroundColor: 'rgba(104, 77, 244, 0.2)',
  transform: 'translate(-50%, -50%)',
  animation: `heroripple 8s ${delay}s linear infinite`,
  '@keyframes heroripple': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(0)',
      opacity: 0.5,
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(4)',
      opacity: 0,
    },
  },
}));

const MarketingService = () => {
  const ripples = [0, 1, 2, 3, 4];
  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    <Box position="relative">
      <Grid container sx={{ py: 4, backgroundColor: '#f0f4ff', position: 'relative' }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: 'center',
            marginTop: { xs: 4, md: -3 },
            position: 'relative',
            padding: { xs: 2, md: 9 },
          }}
        >
          <Box
            sx={{
              maxWidth: '80%',
              textAlign: { xs: 'center', md: 'left' },
              zIndex: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: '#684df4', marginBottom: 1, fontSize: { xs: 15, md: 20 } }}
            >
              Click. Convert. Grow
            </Typography>

            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '28px', md: '48px' },
                fontFamily: 'sans-serif',
                fontWeight: 600,
              }}
            >
              Your Digital Success Partner
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ marginTop: 3 }}>
              At SN Info Systems, we bring your ideas to life with comprehensive UI/UX design, digital marketing, business analysis, and software solutions. From captivating user experiences to robust software, we've got you covered. Let us turn your concepts into impactful solutions and set your business on the path to success.
            </Typography>
            <Box sx={{ my: 4 }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#6C4BFE', mr: { md: 3 }, mb: { xs:1,md:3  }, mt: { md: 3.5 } }}
              >
                ABOUT US
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#6C4BFE',
                  borderColor: '#6C4BFE',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                }}
              >
                <PlayCircleFilledWhiteIcon sx={{ color: '#6C4BFE', mr: 1 }} />
                Watch Our Story
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Box sx={{ position: 'relative', textAlign: 'center', width: '100%' }}>
            {ripples.map((_, index) => (
              <Ripple
                key={index}
                delay={index * 1}
                sx={{
                  marginTop: { xs: -62, md: 9.5 },
                  marginLeft: { xs: '32%', md: -78},
                }}
              />
            ))}
            <img
              src={heroimg}
              alt="heroimg"
              style={{
                maxWidth: '100%',
                height: isDesktop ? 'auto' : 'auto',
                width: isDesktop ? 'auto' : '100%',
                marginBottom: isDesktop ? 0 : 2,
                zIndex: 2,
                transform: 'scale(0.9)', 
                marginLeft: isDesktop ? '-150px' : 0, 
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketingService;
