import React, { useState } from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
import Slider from 'react-slick';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CampaignIcon from '@mui/icons-material/Campaign';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CodeIcon from '@mui/icons-material/Code';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage from '../images/service_bg_2.jpg';
import { styled } from '@mui/system';

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .slick-prev, & .slick-next': {
    zIndex: 1,
    width: 30,
    height: 30,
    '&:before': {
      fontSize: 30,
      color: 'white', // Change the color to white
    },
  },
  '& .slick-prev': {
    left: -40,
    [theme.breakpoints.down('sm')]: {
      left: -20,
    },
  },
  '& .slick-next': {
    right: -40,
    [theme.breakpoints.down('sm')]: {
      right: -20,
    },
  },
}));

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const cards = [
    {
      title: 'UI/UX Design',
      icon: <DesignServicesIcon />,
      description:
        ' Create engaging and intuitive interfaces that enhance user experience.',
    },
    {
      title: 'Digital Marketing',
      icon: <CampaignIcon />,
      description:
        ' Boost your online presence and reach your target audience effectively.',
    },
    {
      title: 'Business Analysis',
      icon: <BusinessCenterIcon />,
      description:
        'Optimize your business processes and make data-driven decisions.',
    },
    {
      title: 'Software Services',
      icon: <CodeIcon />,
      description:
        'Develop robust and scalable software solutions to meet your needs.',
    },
  ];

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        marginTop: 4,
        position: 'relative',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
      }}
    >
      <Container>
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'sans-serif',
            fontSize: 20,
            color: '#684df4',
            fontWeight: 600,
            paddingTop: 10,
            textAlign: { md: 'left', xs: 'center' },
          }}
        >
          OUR SERVICES
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'sans-serif',
            fontWeight: 800,
            marginTop: 2,
            textAlign: { md: 'left', xs: 'center' },
            color: '#ffff',
          }}
        >
         We Provide Expert Solutions  <br /> For <span style={{ color: '#7C4DFF' }}>Your Success</span>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#7C4DFF',
              color: '#fff',
              fontWeight: 'bold',
              padding: '10px 20px',
              textTransform: 'none',
              marginLeft: { md: 115 },
              marginTop: { md: -8, xs: 3 },
              '&:hover': {
                backgroundColor: '#684df4',
              },
            }}
          >
            VIEW ALL SERVICES
            <ArrowForwardIcon sx={{ marginLeft: 1 }} />
          </Button>
        </Box>

        <StyledSlider {...settings}>
          {cards.map((card, index) => (
            <Grid key={index}>
              <Card
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  transform: hoveredCard === index ? 'scale(1.05)' : 'scale(1)',
                  transition: 'transform 0.3s',
                  margin: '0 10px',
                  marginTop: 7,
                  marginBottom: { md: 7, xs: 5 },
                  textAlign: 'center',
                  padding: 2,
                  boxShadow: 3,
                  backgroundColor: hoveredCard === index ? '#7C4DFF' : '#fff',
                  '&:hover': {
                    boxShadow: 6,
                    backgroundColor: '#7C4DFF',
                  },
                }}
              >
                <CardMedia>
                  <Grid sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                    <Grid
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: '50%',
                        backgroundColor: hoveredCard === index ? '#fff' : '#7f3ff2',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: hoveredCard === index ? '#7C4DFF' : '#fff',
                        transition: 'background-color 0.3s, color 0.3s, transform 0.3s',
                        transform: hoveredCard === index ? 'rotateY(360deg)' : 'none',
                      }}
                    >
                      {card.icon}
                    </Grid>
                  </Grid>
                </CardMedia>
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: 'sans-serif', fontWeight: 600 }}>
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2, color: hoveredCard === index ? '#fff' : 'textSecondary' }}>
                    {card.description}
                  </Typography>
                </CardContent>
                <Button size="small" variant="contained" sx={{ backgroundColor: '#7f3ff2', color: '#fff', marginTop: 2 }}>
                  READ MORE
                </Button>
              </Card>
            </Grid>
          ))}
        </StyledSlider>
      </Container>
    </Box>
  );
};

export default Services;
