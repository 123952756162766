import { Grid } from '@mui/material'
import React from 'react'
import About from '../aboutus/About';
import Itsolution from '../aboutus/Itsolutions';
// import Team from '../aboutus/Team';
import Finished from '../aboutus/Finished';
import Chooseus from '../aboutus/Chooseus';
import Happyclints from '../aboutus/Happyclints';
import Footer from '../Footer';

const Abouthome = () => {
  return (
    <Grid>
        <About/>
        <Itsolution/>
        {/* <Team/> */}
        <Finished/>
        <Chooseus/>
        <Happyclints/>
        <Footer/>


    </Grid>
  )
}

export default Abouthome