import React, { useState } from 'react';
import { Grid, Typography, Box, Button, Card, CardContent, CardMedia, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import allmix from '../images/allmix.png';
import reactall from '../images/react sj.png';
import image from '../images/second screen.png';

const StyledContainer = styled('div')({
    perspective: '1000px',
});

const StyledImage = styled('img')({
    width: '100%',
    height: 'auto',
    transition: 'transform 0.1s ease',
    '&:hover': {
        transform: 'rotateY(10deg) rotateX(10deg)',
    },
});

const App = () => {
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = (index) => {
        setValue(index);
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={6} sx={{ marginTop: 3, display: 'flex', justifyContent: 'center' }}>
                    <StyledContainer sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <StyledImage src={image} alt="photo" />
                    </StyledContainer>
                </Grid>
                <Grid item md={6} sx={{ marginTop: 1 }}>
                    <Typography variant="h6"
                        sx={{
                            color: '#684df4',
                            marginTop: 6,
                            marginLeft: { md: 5, xs: -1 },
                            fontWeight: 700,
                            fontFamily: 'sans-serif',
                            fontSize: { xs: 15, md: 25 },
                            textAlign: { xs: 'center', md: 'left' }
                        }}>
                        About SN Info Systems
                    </Typography>
                    <Typography variant="h4"
                        sx={{
                            marginTop: 2,
                            marginLeft: { md: 5, xs: -0.1 },
                            fontWeight: { md: 700, xs: 700 },
                            width: { xs: '100%' },
                            fontFamily: "sans-serif",
                            fontSize: { md: 40, xs: 26 },
                            textAlign: { xs: 'center', md: 'left' }
                        }}>
                        Elevating Your Business <br />with
                        <span style={{ color: '#684df4' }}> Technology</span>
                    </Typography>
                    <Typography variant="subtitle1"
                        sx={{
                            color: '#666',
                            marginTop: 2,
                            marginLeft: { md: 5, xs: 0.8 },
                            textAlign: { xs: 'center', md: 'left' },
                            justifyContent: { xs: 'initial' },
                            fontSize: { xs: 16 },
                            width: { xs: '95%' }
                        }}>
                        At SN Info Systems, we're dedicated to leveraging cutting-edge technology to elevate your business and drive growth and innovation.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Grid container spacing={2} sx={{ width: 'auto', justifyContent: 'center',marginLeft:{md:-15,xs:1} }}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={() => handleClick(0)}
                                    sx={{
                                        backgroundColor:'#684df4',
                                        color: '#fff',
                                        borderRadius: 0,
                                        fontSize: { xs: '13px', md: '21px' },
                                        '&:hover': {
                                            backgroundColor:'#684df4',
                                        },
                                    }}
                                >
                                    OUR SERVICES
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={() => handleClick(1)}
                                    sx={{
                                        backgroundColor: '#141d38',
                                        color: '#fff',
                                        borderRadius: 0,
                                        fontSize: { xs: '13px', md: '21px' },
                                        '&:hover': {
                                            backgroundColor:'#141d38',
                                        },
                                    }}
                                >
                                    TECHNOLOGY INDEX
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} display={value === 0 ? 'block' : 'none'}>
                            <Card sx={{ margin: '16px', padding: '32px', width: {md:'80%',xs:'70%'},marginLeft:{md:5,xs:3} }}>
                                    <Grid container spacing={2} alignItems="center" direction={isMobile ? "column" : "row"}>
                                        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
                                            <CardMedia
                                                component="img"
                                                image={allmix}
                                                alt="Award"
                                                sx={{ width: '200px', height: '200px', borderRadius: '8px',marginLeft:{md:3} }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <CardContent sx={{textAlign: isMobile ? 'center' : 'left', justifyContent:'center', marginLeft: isMobile ? 0 : 4}}>
                                                <Typography
                                                    variant="h5"
                                                    component="div"
                                                    gutterBottom
                                                    sx={{ color: '#0d1a3f', fontWeight: 600, fontSize: '1.5rem', fontFamily: 'sans-serif' }}
                                                >
                                                    We Provide Expert Solutions for Your Success

                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                    sx={{ marginBottom: '16px', fontSize: '1rem' }}
                                                >
                                                    At SN Info Systems, we offer tailored services to help your business thrive in the digital age.

                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#7f3ff2', color: '#fff', fontSize: '1rem', padding: '10px 20px' }}
                                                >
                                                    ABOUT MORE
                                                </Button>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid item xs={12} display={value === 1 ? 'block' : 'none'}>
                            <Card sx={{ margin: '16px', padding: '32px', width: {md:'80%',xs:'70%'},marginLeft:{md:5,xs:3} }}>
                                    <Grid container spacing={2} alignItems="center" direction={isMobile ? "column" : "row"}>
                                        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
                                            <CardMedia
                                                component="img"
                                                image={reactall}
                                                alt="Award"
                                                sx={{ width: '200px', height: '200px', borderRadius: '8px',marginLeft:{md:3} }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <CardContent sx={{textAlign: isMobile ? 'center' : 'left', justifyContent:'center', marginLeft: isMobile ? 0 : 4}}>
                                                <Typography
                                                    variant="h5"
                                                    component="div"
                                                    gutterBottom
                                                    sx={{ color: '#0d1a3f', fontWeight: 600, fontSize: '1.5rem', fontFamily: 'sans-serif' }}
                                                >
                                                    We're all in on your success. 
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                    sx={{ marginBottom: '16px', fontSize: '1rem' }}
                                                >
                                                    My Team dedication fuels your achievements. Together, we reach your goals. Let's get started!
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#7f3ff2', color: '#fff', fontSize: '1rem', padding: '10px 20px' }}
                                                >
                                                    ABOUT MORE
                                                </Button>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                            </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default App;
