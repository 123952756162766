import React from 'react';
import { Grid, Typography, Breadcrumbs, Link } from '@mui/material';
import backgroundImage from '../../images/aboutusbacgroundimage.jpg';

const About = () => {
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: (theme) => theme.spacing(4),
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', color: '#fff' }}>
          About Us
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <Breadcrumbs sx={{ color: '#fff', textAlign: 'center' }}>
          <Link href="/" sx={{ color: 'inherit', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
            Home
          </Link>
          <Typography sx={{color:'#ffff'}}>About Us</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default About;
