import { Grid } from '@mui/material'
import React from 'react'
import Contacthome from '../contactus/contact';
import Email from '../contactus/Email';
import Form from '../contactus/Form';
import Footer from '../Footer';

const ContacthomeComponent = () => {
  return (
    <Grid>
        <Contacthome/>
        <Email/>
        <Form/>
        <Footer/>


    </Grid>
  )
}

export default ContacthomeComponent