import React, { useEffect } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';
import Image from '../../images/aboutus2.jpg';
import { useInView } from 'react-intersection-observer';

const services = [
  'Customized Business Solutions',
  'Big Data Expertise',
  'Robust Security Measures',
  '24/7 Online Support',
  'Dedicated Expert Team',
  'Continuous Improvement Focus',
];

const floatingAnimation = {
  initial: { scale: 1, rotate: 0 },
  animate: { scale: [1, 1.05, 1], rotate: [0, 5, -5, 0] },
  transition: {
    duration: 6,
    ease: 'easeInOut',
    repeat: Infinity,
    repeatType: 'mirror',
  },
};

const AboutUs = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.5, // Adjust as needed
  });

  useEffect(() => {
    if (inView) {
      
    }
  }, [inView]);

  return (
    <Container sx={{ py: 8 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="h6" sx={{ color: '#684df4', fontWeight: 800 }}>
              WHY CHOOSE US
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 700,fontFamily:'sans-serif',fontSize:38 }}>
            Your Trusted Partner in Business<span style={{color:'#684df4'}}> Solutions</span>
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', my: 2 }}>
            At SN Info Systems, we specialize in delivering professional services tailored to your business needs. Our collaborative approach ensures user-friendly solutions and strategic alignment across all units. 
            </Typography>
            <Grid container spacing={2}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircleIcon sx={{ color: '#684df4', mr: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>{service}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
          <Box
            component={motion.img}
            ref={ref} 
            src={Image}
            alt="IT Services"
            initial="initial"
            animate={inView ? 'animate' : 'initial'}
            variants={floatingAnimation}
            sx={{
              width: '100%',
              borderRadius:'10px'
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
