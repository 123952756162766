import React, { useState } from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import Slider from 'react-slick';
import GetMySchool from '../images/getmyschool (2).jpg';
import GetMyCollege from '../images/GetMyCollege.jpg';
import GetMyProperty from '../images/GetMyProperty.jpg'; 
import schoolerp from '../images/School Management Software (ERP Software).jpg';
import collegeerp from '../images/College Management Software (ERP Software).jpg'
import LeadMaster from '../images/LeadMaster.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from '@mui/system';

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .slick-prev, & .slick-next': {
    zIndex: 1,
    width: 30,
    height: 30,
    '&:before': {
      fontSize: 30,
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
      '&:before': {
        fontSize: 25,
      },
    },
  },
  '& .slick-prev': {
    left: -40,
    [theme.breakpoints.down('sm')]: {
      left: -15, 
    },
  },
  '& .slick-next': {
    right: -40,
    [theme.breakpoints.down('sm')]: {
      right: -15, 
    },
  },
}));

const ProjectsSection = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const cards = [
    {
      title: 'GetMyCollege ',
      image: GetMyCollege,
      description:
        'An all-in-one college search platform that simplifies finding the perfect college. Compare options, access comprehensive information, and make informed decisions with ease.',
    },
    {
      title: 'GetMySchool',
      image: GetMySchool,
      description:
        'Your ultimate school search companion. Discover detailed school profiles, including curriculum, environment, and extracurriculars, to find the best fit for your child.',
    },
    {
      title: 'GetMyProperty',
      image: GetMyProperty,
      description:
        'A robust platform for discovering, listing, and managing real estate properties. Whether you are buying, selling, or renting, GetMyProperty provides tools and resources to simplify the process.',
    },
    {
      title: 'School ERP',
      image: schoolerp,
      description:
        ' Integrated ERP solution for managing school operations, covering admissions, attendance, examinations, and communication for effective administration.',
    },
    {
      title: 'College  ERP',
      image: collegeerp,
      description:
        ' Comprehensive ERP system designed to streamline college administration, managing student information, faculty, courses, finances, and more efficiently.',
    },
    {
      title: 'LeadMaster',
      image: LeadMaster,
      description:
        ' A powerful CRM tool designed to streamline and optimize lead management. It helps businesses track, nurture, and convert leads efficiently, boosting overall sales performance.',
    },
  ];

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <Grid container justifyContent="center" sx={{ overflow: 'visible' }}>
      <Container sx={{ marginTop: 1, marginBottom: 8, overflow: 'visible' }}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontFamily: 'sans-serif',
            fontSize: 20,
            color: '#684df4',
            fontWeight: 600,
            marginTop: { md: 5, xs: 2 },
            textAlign: 'center',
          }}
        >
          PROJECTS
        </Typography>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontFamily: 'sans-serif',
            fontWeight: { md: 800, xs: 800 },
            marginTop: { md: 2, xs: 2 },
            marginBottom: 4,
            textAlign: 'center',
          }}
        >
          Our Recent Latest <span style={{ color: '#7C4DFF' }}>Projects</span>
        </Typography>

        <StyledSlider {...settings}>
          {cards.map((card, index) => (
            <Grid key={index} sx={{ position: 'relative', overflow: 'visible' }}>
              <Card
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  transform: hoveredCard === index ? 'scale(1.05)' : 'scale(1)',
                  transition: 'transform 0.3s',
                  margin: '0 10px',
                  zIndex: hoveredCard === index ? 1 : 1,
                  position: 'relative',
                  marginBottom:1,
                  marginTop:1,
                  height:'380px'
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={card.image}
                  alt={card.title}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ fontFamily: 'sans-serif', fontWeight: 600, textAlign: 'center' }}>
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2, textAlign: 'center' }}>
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </StyledSlider>
      </Container>
    </Grid>
  );
};

export default ProjectsSection;
