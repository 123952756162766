import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import counter1 from '../../images/counter_1_1.svg';
import counter2 from '../../images/counter_1_2.svg';
import counter3 from '../../images/counter_1_3.svg';
import counter4 from '../../images/counter_1_4.svg';

const data = [
  { icon: counter1, count: 50, label: 'Finished Project' },
  { icon: counter2, count: 45, label: 'Happy Clients' },
  { icon: counter3, count: 20, label: 'Skilled Experts' },
  { icon: counter4, count: 5, label: 'Honorable Awards' },
];

const Finished = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [ref, inView] = useInView({ triggerOnce: true });
  const [startCounter, setStartCounter] = useState(false);

  useEffect(() => {
    if (inView) {
      setHoveredIndex(0); // Start with the first item
      setStartCounter(true);
    }
  }, [inView]);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <Box sx={{ backgroundColor: '#684df4', padding: '50px 0', textAlign: 'center' }}>
      <Container>
        <Grid container spacing={4}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                elevation={3}
                sx={{
                  padding: '20px',
                  borderRadius: '20px',
                  backgroundColor: '#fff',
                  color: '#000',
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                ref={ref}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 2,
                    transform: hoveredIndex === index ? 'rotateY(180deg)' : 'rotateY(0deg)',
                    transition: 'transform 0.5s',
                  }}
                >
                  <img src={item.icon} alt={item.label} style={{ width: 60, height: 60 }} />
                </Box>
                <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#684df4' }}>
                  {startCounter ? (
                    <CountUp end={item.count} duration={2} suffix="+" />
                  ) : (
                    `${item.count}+`
                  )}
                </Typography>
                <Typography variant="h6">{item.label}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Finished;
